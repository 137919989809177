.header {
    height: 147px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    > .container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;

        > .top {
            height: 94px;
            width: 100%;
            padding: 0 75px 14px 75px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            background-color: #fff;

            > .title {
                // width: 270px;
                height: 57px;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                }

                > p {
                    font-size: 28px;
                    font-weight: 600;
                    color: #ff6116;
                }
            }

            > .search {
                // margin-left: 401px;
                margin-left: 100px;
                display: flex;
                flex-direction: column;

                > .input-block {
                    display: flex;
                    > .input {
                        width: 264px;
                        padding: 0 12px;
                        height: 42px;
                        overflow: hidden;
                        border-radius: 3px 0 0 3px;
                        border: 1px solid #acacac;
                        border-right: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.85);
                        &::input-placeholder {
                            color: #999999;
                        }

                        &::placeholder {
                            color: #999999;
                        }
                    }

                    > .suffix {
                        width: 52px;
                        cursor: pointer;
                        background: #ff6116;
                        border-radius: 0 3px 3px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > .icon {
                            width: 20px;
                            height: 21px;
                        }
                    }
                }

                > .hot-search {
                    font-size: 12px;
                    font-weight: 400;
                    color: #a2a2a2;
                    line-height: 18px;
                    padding-top: 4px;
                }
            }
        }

        .menu {
            width: 100%;
            min-width: 1000px;
            padding: 0 75px;
            height: 53px;
            background: #ff6116;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-item {
                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
                cursor: pointer;
            }
        }

        @media (min-width: 1200px) {
            .top {
                padding-right: 240px;
                padding-left: 240px;
            }
            .menu {
                padding: 0 240px;
            }
        }
    }
}
