.main {
    min-width: 1400px;

    .footer {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > a {
            cursor: pointer;
            font-size: 14px;
        }
    }
}
